import {
  AppBskyFeedDefs,
  BskyAgent,
  ComAtprotoRepoListRecords,
} from '@atproto/api'

import {FeedAPI, FeedAPIResponse} from './types'
import {BSKY_SERVICE} from '#/lib/constants'

export class FollowerLikesFeedAPI implements FeedAPI {
  agent: BskyAgent
  params: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>

  constructor({
    agent,
    feedParams,
  }: {
    agent: BskyAgent
    feedParams: Omit<ComAtprotoRepoListRecords.QueryParams, 'collection'>
  }) {
    this.agent = agent
    this.params = feedParams
  }

  async peekLatest(): Promise<AppBskyFeedDefs.FeedViewPost> {
    return await fetch(
      `${BSKY_SERVICE}/xrpc/com.atproto.repo.listRecords?collection=app.bsky.feed.like&limit=1&reverse=${this.params.reverse}&cursor=&repo=${this.params.repo}`,
    )
      .then(res => res.json())
      .then(async data => {
        const res = await this.agent.getPosts({
          uris: data.records.map((record: any) => record?.value?.subject?.uri),
        })
        const posts =
          res.data?.posts.map(post => {
            return {
              post: post,
            }
          }) || []
        return posts[0]
      })
  }

  async fetch(): Promise<FeedAPIResponse> {
    return await fetch(
      `${BSKY_SERVICE}/xrpc/com.atproto.repo.listRecords?collection=app.bsky.feed.like&limit=${this.params.limit}&reverse=${this.params.reverse}&cursor=&repo=${this.params.repo}`,
    )
      .then(res => res.json())
      .then(async data => {
        const cursor = data.cursor
        const res = await this.agent.getPosts({
          uris: data.records.map((record: any) => record?.value?.subject?.uri),
        })
        const posts =
          res.data?.posts.map(post => {
            return {
              post: post,
            }
          }) || []
        if (res.success && posts.length > 0) {
          return {
            cursor: cursor || undefined,
            feed: posts,
          }
        }
        return {
          cursor: undefined,
          feed: [],
        }
      })
  }
}
